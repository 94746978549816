import OrderCreation from './OrderCreation.js';

export default class BookingOrder extends OrderCreation {
  constructor({ amount, selectedPasses, answers, travelerId, productId }) {
    super();
    const passes = this.mapAndValidatePasses(selectedPasses);
    const mappedAnswers = this.mapAnswers(answers);
    const validatedAmount = this.validateAmount(amount);

    this.init({
      travelerId,
      amount: validatedAmount,
      products: [
        {
          id: productId,
          answers: mappedAnswers,
          passes: passes,
        },
      ],
    });
  }

  validateAmount(
    { value = null, currency = null } = { value: null, currency: null }
  ) {
    if (value === null) throw new Error('Amount value cannot be null');
    if (currency === null) throw new Error('Amount value cannot be null');
    return {
      value,
      currency,
    };
  }

  mapAnswers(answers) {
    let answerList = [];

    if (answers != null) {
      const answerEntries = Object.entries(answers);
      for (const [key, value] of answerEntries) {
        answerList.push({
          questionId: key,
          value: value,
        });
      }
    }
    return answerList;
  }

  mapAndValidatePasses(passes) {
    if (passes == null || passes.length === 0)
      throw new Error('Passes cannot be null');
    let passList = [];
    passes.forEach((pass) => {
      for (let i = 0; i < pass.value; i++) {
        passList.push(pass.id);
      }
    });
    return passList;
  }
}
