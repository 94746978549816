export const DETAILS_LOADED = 'DETAILS_LOADED';
export const DETAILS_ERROR = 'DETAILS_ERROR';
export const DETAILS_LOADING = 'DETAILS_LOADING';
export const SIMILAR_PRODUCTS_LOADED = 'SIMILAR_PRODUCTS_LOADED';
export const SIMILAR_PRODUCTS_ERROR = 'SIMILAR_PRODUCTS_ERROR';
export const SIMILAR_PRODUCTS_LOADING = 'SIMILAR_PRODUCTS_LOADING';
export const AVAILABILTY_LOADING = 'AVAILABILTY_LOADING';
export const AVAILABILTY_ERROR = 'AVAILABILTY_ERROR';
export const AVAILABILTY_LOADED = 'AVAILABILTY_LOADED';
export const PASSES_LOADING = 'PASSES_LOADING';
export const PASSES_ERROR = 'PASSES_ERROR';
export const PASSES_LOADED = 'PASSES_LOADED';
export const ORDER_PASSES_SET = 'ORDER_PASSES_SET';
export const ORDER_PASSES_CLEAR = 'ORDER_PASSES_CLEAR';
export const ORDER_PASSES_READY = 'ORDER_PASSES_READY';
export const ORDER_AVAILABILITY_DATE_SET = 'ORDER_AVAILABILITY_DATE_SET';
export const ORDER_OPTION_SET = 'ORDER_OPTION_SET';
export const QUESTIONS_LOADING = 'QUESTIONS_LOADING';
export const QUESTIONS_ERROR = 'QUESTIONS_ERROR';
export const QUESTIONS_LOADED = 'QUESTIONS_LOADED';
export const ORDER_QUESTIONS_SET = 'ORDER_QUESTIONS_SET';
export const ORDER_QUESTIONS_SET_TRAVELERS = 'ORDER_QUESTIONS_SET_TRAVELERS';
export const QUESTIONS_PRELOAD = 'QUESTIONS_PRELOAD';
export const ORDER_QUESTIONS_CLEARED = 'ORDER_QUESTIONS_CLEARED';
export const ORDER_UPDATE_AMOUNT = 'ORDER_UPDATE_AMOUNT';
export const ORDER_INFORMATION_CLEAR = 'ORDER_INFORMATION_CLEAR';
export const AVAILABILTY_CLEAR = 'AVAILABILTY_CLEAR';
export const PASSES_CLEAR = 'PASSES_CLEAR';
export const QUESTIONS_CLEAR = 'QUESTIONS_CLEAR';
export const ORDER_APPEND_LOCAL_ID = 'ORDER_APPEND_LOCAL_ID';
export const SET_ORDER_REQUEST = 'SET_ORDER_REQUEST';
