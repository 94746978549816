<script>
import ProductStoreModule from '../store/index.js';
import ProductBookingMixins from './ProductBookingMixins.vue';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';

export default {
  name: 'ProductDetailsMixins',
  mixins: [ProductBookingMixins, TravelerMixins],
  methods: {
    registerStore() {
      const store = this.$store;
      if (!ProductStoreModule[`registered-${this.storeModuleName}`]) {
        store.registerModule(this.storeModuleName, ProductStoreModule, {
          preserveState: !!store.state[this.storeModuleName],
        });
        ProductStoreModule[`registered-${this.storeModuleName}`] = true;
      }
    },
    loadProductDetails(travelerId = this.travelerId) {
      if (!this.hasDetails) {
        this.$store.dispatch(`${this.storeModuleName}/loadDetails`, {
          productId: this.productId,
          travelerId,
        });
      }
    },
    loadSimilarProducts() {
      this.$store.dispatch(`${this.storeModuleName}/loadSimilarProducts`, {
        productIds: this.productId,
      });
    },
  },
  computed: {
    productDetails() {
      if (!!this.$store.state[this.storeModuleName]) {
        return this.$store.state[this.storeModuleName].details;
      } else {
        return null;
      }
    },
    hasDetails() {
      return this.$store.getters[`${this.storeModuleName}/hasDetails`];
    },
    similarProducts() {
      if (!!this.$store.state[this.storeModuleName]) {
        return this.$store.state[this.storeModuleName].similarProducts;
      } else {
        return null;
      }
    },
    similarProductsList() {
      return this.hasSimilarProducts ? this.similarProducts.value.groups : null;
    },
    displayedSimilarProducts() {
      return this.similarProductsList
        ? this.similarProductsList.filter((catalog) => catalog.items.length)
        : [];
    },
    hasSimilarProducts() {
      return this.$store.getters[`${this.storeModuleName}/hasSimilarProducts`];
    },
    error() {
      if (!!this.productDetails) return this.productDetails.error;
      else return null;
    },
    isLoading() {
      return (
        (!!this.productDetails && this.productDetails.isLoading) ||
        this.error !== null
      );
    },
    detailsModel() {
      if (!!this.productDetails) return this.productDetails.value;
      else return null;
    },
  },
};
</script>
