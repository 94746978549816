<script>
export default {
  name: 'ProductCheckoutMixins',
  data() {
    return {
      useDiscounts: false,
    };
  },
  created() {
    this.checkDiscountFF();
  },
  computed: {
    mLocalOrderId() {
      if (!!this.localOrderId) return this.localOrderId;
      else {
        // fallback to route params
        return !!this.$route.params.localOrderId
          ? this.$route.params.localOrderId
          : '';
      }
    },
    localOrder() {
      return !!this.mLocalOrderId
        ? this.$store.state.product.orders[this.mLocalOrderId]
        : null;
    },
    hasOrderPaymentError() {
      return this.hasOrderCreationError || this.hasOrderCheckoutError;
    },
    orderPaymentError() {
      if (this.hasOrderCheckoutError) {
        return this.orderCheckout.error;
      } else if (this.hasOrderCreationError) {
        return this.orderCreation.error;
      } else {
        return null;
      }
    },
    orderRequest() {
      return !!this.localOrder ? this.localOrder.orderRequest : null;
    },
    orderCreation() {
      return !!this.localOrder ? this.localOrder.creation : null;
    },
    orderCheckout() {
      return !!this.localOrder ? this.localOrder.checkout : null;
    },
    hasCreatedOrder() {
      return !!this.orderCreation && !!this.orderCreation.response;
    },
    hasCheckedOutOrder() {
      return !!this.orderCheckout && !!this.orderCheckout.response;
    },
    checkoutStatus() {
      return !!this.hasCheckedOutOrder
        ? this.orderCheckout.response.status
        : null;
    },
    isAsyncCheckout() {
      return (
        !!this.orderCheckout &&
        !!this.orderCheckout.response &&
        !!this.orderCheckout.async
      );
    },
    hasOrderCreationError() {
      return !!this.orderCreation && !!this.orderCreation.error;
    },
    hasOrderCheckoutError() {
      return !!this.orderCheckout && !!this.orderCheckout.error;
    },
    isCheckingOutOrder() {
      return !!this.orderCheckout && this.orderCheckout.isCheckingOut;
    },
    isCreatingOrder() {
      return !!this.orderCreation && this.orderCreation.isCreating;
    },
    orderCreationStatus() {
      return !!this.orderCreation ? this.createdOrder.status : null;
    },
    isLoadingOrder() {
      return this.isCheckingOutOrder || this.isCreatingOrder;
    },
    createdOrder() {
      return !!this.orderCreation ? this.orderCreation.response : null;
    },
    checkedOutOrder() {
      return !!this.orderCheckout ? this.orderCheckout.response : null;
    },
    createdOrderId() {
      return !!this.createdOrder ? this.createdOrder.id : null;
    },
    checkOutConfirmationKey() {
      return !!this.orderCheckout
        ? this.orderCheckout.clientConfirmationKey
        : null;
    },
    createdDiscountToken() {
      if (!!this.createdOrder && !!this.createdOrder.discount)
        return this.createdOrder.discount.discountToken;
      else return null;
    },
    hasOrderCheckoutFatalError() {
      return !!this.orderCheckout && !!this.orderCheckout.fatalError;
    },
    orderCheckoutTimeout() {
      return !!this.orderCheckout && this.orderCheckout.maxpollRetry === 0;
    },
    amount() {
      if (this.hasCreatedOrder) {
        return this.createdOrder.amount;
      } else {
        return {
          value: 0,
          baseCurrency: 'USD',
        };
      }
    },
    discount() {
      if (
        !this.useDiscounts ||
        !this.hasCreatedOrder ||
        !this.createdOrder.discount
      )
        return null;
      return {
        label: this.createdOrder.discount.label,
        name: this.createdOrder.discount.promoCode,
        discountAmount: this.createdOrder.amount.discountTotal,
        finalTotal: this.createdOrder.amount.value,
        termsAndConditions: this.createdOrder.discount.termsAndConditions,
      };
    },
    orderProductId() {
      return !!this.localOrder ? this.localOrder.productId : null;
    },
  },
  methods: {
    async checkDiscountFF() {
      this.useDiscounts = await this.$ldclient.variation(
        'discount',
        false,
        true
      );
      this.$ldclient.$on('change:discount', (value) => {
        this.useDiscounts = value;
      });
    },
    removeDiscount() {
      return this.$store.dispatch('product/removeDiscount', {
        orderId: this.mLocalOrderId,
      });
    },
    checkOutOrder(
      {
        localOrderId = this.mLocalOrderId,
        orderId,
        paymentMethodId,
        save,
        discountToken,
        sourceId,
        paymentMethodType,
        asyncCall = false,
      } = {
        localOrderId: this.mLocalOrderId,
      }
    ) {
      if (!!localOrderId) {
        return this.$store.dispatch(`product/checkOutOrder`, {
          id: localOrderId,
          paymentMethodId,
          save,
          orderId,
          discountToken,
          sourceId,
          paymentMethodType,
          asyncCall,
        });
      }
    },
    clearOrderState(
      { localOrderId = this.mLocalOrderId } = {
        localOrderId: this.mLocalOrderId,
      }
    ) {
      this.clearOrderCreationState();
      this.clearOrderCheckOutState();
    },
    clearOrderCreationState(
      { localOrderId = this.mLocalOrderId } = {
        localOrderId: this.mLocalOrderId,
      }
    ) {
      this.$store.dispatch(`product/clearOrderCreation`, {
        id: localOrderId,
      });
    },
    clearOrderCheckOutState(
      { localOrderId = this.mLocalOrderId } = {
        localOrderId: this.mLocalOrderId,
      }
    ) {
      this.$store.dispatch(`product/clearOrderCheckout`, {
        id: localOrderId,
      });
    },
    createOrder(
      { order, localOrderId = this.mLocalOrderId } = {
        order: null,
        localOrderId: this.mLocalOrderId,
      }
    ) {
      // prepare the order here
      // remap to create the order request object here
      return this.$store.dispatch(`product/createOrderRequest`, {
        id: localOrderId,
        order,
      });
    },
    startCheckoutpolling(
      { order, localOrderId = this.mLocalOrderId, orderId } = {
        order: null,
        orderId,
        localOrderId: this.mLocalOrderId,
      }
    ) {
      return this.$store.dispatch(`product/startCheckOutOrderpolling`, {
        id: localOrderId,
        order,
        orderId,
      });
    },
    updateDiscount(response) {
      this.$store.dispatch('product/updateDiscount', {
        orderId: this.mLocalOrderId,
        response,
      });
    },
  },
};
</script>
