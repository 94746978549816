import {
  DETAILS_LOADED,
  DETAILS_LOADING,
  DETAILS_ERROR,
  SIMILAR_PRODUCTS_LOADED,
  SIMILAR_PRODUCTS_LOADING,
  SIMILAR_PRODUCTS_ERROR,
  AVAILABILTY_LOADING,
  AVAILABILTY_ERROR,
  AVAILABILTY_LOADED,
  PASSES_LOADING,
  PASSES_ERROR,
  PASSES_LOADED,
  ORDER_PASSES_SET,
  ORDER_PASSES_CLEAR,
  ORDER_PASSES_READY,
  ORDER_AVAILABILITY_DATE_SET,
  ORDER_OPTION_SET,
  QUESTIONS_LOADING,
  QUESTIONS_ERROR,
  QUESTIONS_LOADED,
  ORDER_QUESTIONS_SET,
  ORDER_QUESTIONS_SET_TRAVELERS,
  QUESTIONS_PRELOAD,
  ORDER_QUESTIONS_CLEARED,
  ORDER_UPDATE_AMOUNT,
  ORDER_INFORMATION_CLEAR,
  AVAILABILTY_CLEAR,
  PASSES_CLEAR,
  QUESTIONS_CLEAR,
  ORDER_APPEND_LOCAL_ID,
  SET_ORDER_REQUEST,
  SET_REDIRECTED_ORDER_INFO,
} from './mutation-types';
import Vue from 'vue';

export const mutations = {
  [DETAILS_LOADED](state, { product } = { product: {} }) {
    state.details = { ...state.details, value: { ...product } };
  },
  [DETAILS_LOADING](state, payload = true) {
    state.details = { ...state.details, isLoading: payload };
  },
  [DETAILS_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.details = {
      ...state.details,
      value: null,
      error: error,
    };
  },
  [SIMILAR_PRODUCTS_LOADED](state, { products } = { products: {} }) {
    state.similarProducts = {
      ...state.similarProducts,
      value: { ...products },
    };
  },
  [SIMILAR_PRODUCTS_LOADING](state, payload = true) {
    state.similarProducts = { ...state.similarProducts, isLoading: payload };
  },
  [SIMILAR_PRODUCTS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.similarProducts = {
      ...state.details,
      value: null,
      error: error,
    };
  },
  [AVAILABILTY_LOADING](state, payload = true) {
    state.availability = { ...state.availability, isLoading: payload };
  },
  [AVAILABILTY_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.availability = { ...state.availability, error: error };
  },
  [AVAILABILTY_LOADED](state, { availability } = { availability: {} }) {
    const newValue = { ...state.availability.value, ...availability };
    state.availability = { ...state.availability, value: { ...newValue } };
  },
  [PASSES_LOADING](state, payload = true) {
    state.passes = { ...state.passes, isLoading: payload };
  },
  [PASSES_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.passes = { ...state.passes, error: error };
  },
  [PASSES_LOADED](state, { passes } = { passes: {} }) {
    state.passes = { ...state.passes, value: { ...passes } };
  },
  [ORDER_PASSES_SET](state, { id, value } = { id: null, value: 0 }) {
    if (!id) return;
    let selectedPasses;

    const updatePass = () => {
      return state.orderInformation.selectedPasses.map((pass) => {
        if (pass.id !== id) return pass;
        return {
          ...pass,
          value: value,
        };
      });
    };

    const removePass = () => {
      return state.orderInformation.selectedPasses.filter(
        (pass) => id !== pass.id
      );
    };

    const addPass = () => {
      return [...state.orderInformation.selectedPasses, { id, value }];
    };

    if (value > 0) {
      const pass = state.orderInformation.selectedPasses.find(
        (pass) => pass.id == id
      );
      if (pass) {
        selectedPasses = updatePass();
      } else {
        selectedPasses = addPass();
      }
    } else {
      selectedPasses = removePass();
    }

    state.orderInformation = {
      ...state.orderInformation,
      selectedPasses,
    };
  },
  [ORDER_PASSES_CLEAR](state) {
    state.orderInformation = {
      ...state.orderInformation,
      selectedPasses: [],
    };
  },
  [ORDER_PASSES_READY](state, { passesReady } = { passesReady: false }) {
    state.orderInformation = {
      ...state.orderInformation,
      passesReady,
    };
  },
  [ORDER_AVAILABILITY_DATE_SET](
    state,
    { availabilityDate } = { availabilityDate: null }
  ) {
    if (!availabilityDate) return;

    state.orderInformation = {
      ...state.orderInformation,
      selectedDate: availabilityDate,
    };
  },
  [ORDER_OPTION_SET](state, { option } = { option: null }) {
    state.orderInformation = {
      ...state.orderInformation,
      option,
    };
  },
  [QUESTIONS_LOADING](state, payload = true) {
    state.questions = { ...state.questions, isLoading: payload };
  },
  [QUESTIONS_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.questions = { ...state.questions, error: error };
  },
  [QUESTIONS_LOADED](state, { questions } = { questions: {} }) {
    if (state.orderInformation.answers != null) {
      for (let i in questions) {
        for (let j in questions[i].questions) {
          let id = questions[i].questions[j].id;
          let question = questions[i].questions[j];
          questions[i].questions[j] = {
            ...question,
            value: state.orderInformation.answers[id],
          };
        }
      }
    }

    state.questions = { ...state.questions, value: { ...questions } };
  },
  [ORDER_QUESTIONS_SET](state, { answers } = { answers: null }) {
    state.orderInformation = {
      ...state.orderInformation,
      answers,
    };
  },
  [ORDER_QUESTIONS_SET_TRAVELERS](state, travelers) {
    state.travelers = travelers;
  },
  [QUESTIONS_PRELOAD](state, { passIds }) {
    const key = passIds.join(':');
    const cachedQuestions = state.questions.questionsByPass[key] || {
      value: null,
      error: null,
      isLoading: false,
      passIds: key,
    };

    const oldQuest =
      state.questions.questionsByPass[state.questions.passIds || key];
    state.questions.questionsByPass[state.questions.passIds || key] = {
      ...oldQuest,
      value: state.questions.value,
      error: state.questions.error,
      isLoading: state.questions.isLoading,
    };
    state.questions = { ...state.questions, ...cachedQuestions };
  },
  [ORDER_QUESTIONS_CLEARED](state) {
    state.questions.value = null;
  },
  [ORDER_UPDATE_AMOUNT](
    state,
    { value, currency } = { value: 0, currency: 'USD' }
  ) {
    state.orderInformation = {
      ...state.orderInformation,
      amount: { value, currency },
    };
  },
  [ORDER_INFORMATION_CLEAR](state) {
    state.orderInformation = {
      amount: null,
      selectedDate: null,
      availabilityDate: null,
      option: null,
      selectedPasses: [],
      passesReady: false,
      answers: null,
      orderByDate: {},
    };
  },
  [AVAILABILTY_CLEAR](state) {
    state.availability = {
      value: null,
      error: null,
      isLoading: false,
    };
  },
  [PASSES_CLEAR](state) {
    state.passes = {
      value: null,
      error: null,
      isLoading: false,
    };
  },
  [QUESTIONS_CLEAR](state) {
    state.questions = {
      passIds: null,
      value: null,
      error: null,
      isLoading: false,
      questionsByPass: {},
    };
  },
  [ORDER_APPEND_LOCAL_ID](state, id) {
    if (!!id) {
      state.localOrderIds = [...state.localOrderIds, id];
    }
  },
  [SET_ORDER_REQUEST](state, orderRequest) {
    Vue.set(state, 'orderRequest', orderRequest);
  },
};
