export const state = () => ({
  details: {
    value: null,
    error: null,
    isLoading: false,
  },
  similarProducts: {
    value: null,
    error: null,
    isLoading: false,
  },
  availability: {
    value: null,
    error: null,
    isLoading: false,
  },
  passes: {
    value: null,
    error: null,
    isLoading: false,
  },
  orderInformation: {
    amount: null,
    selectedDate: null,
    availabilityDate: null,
    option: null,
    selectedPasses: [],
    passesReady: false,
    answers: null,
    orderByDate: {},
  },
  questions: {
    passIds: null,
    value: null,
    error: null,
    isLoading: false,
    questionsByPass: {},
  },
  travelers: [],
  localOrderIds: [],
  orderRequest: null,
});
