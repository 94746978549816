import * as mutations from './mutation-types';
import { RepoFactory } from '@/core/repositories';
import BookingOrder from '../../models/BookingOrder.js';

export const actions = {
  async loadDetails({ commit }, { productId, travelerId }) {
    const bookingService = RepoFactory.get('booking', {
      resourceId: productId,
    });
    commit(mutations.DETAILS_ERROR, { error: null });
    commit(mutations.DETAILS_LOADING, true);
    try {
      const { data } = await bookingService.getDetails({ travelerId });
      commit(mutations.DETAILS_LOADED, { product: data });
    } catch (error) {
      let usefulError = {
        message: error.toString(),
        code: 500,
        status: error.response?.status,
      };

      if (!!error.response && !!error.response.data) {
        usefulError.message = error.response.data.errorMessage;
        usefulError.code = error.response.data.errorCode;
      }

      commit(mutations.DETAILS_ERROR, { error: usefulError });
    } finally {
      commit(mutations.DETAILS_LOADING, false);
    }
  },
  async loadSimilarProducts({ commit }, { productIds }) {
    const catalogGroupService = RepoFactory.get('catalogGroup');
    commit(mutations.SIMILAR_PRODUCTS_ERROR, { error: null });
    commit(mutations.SIMILAR_PRODUCTS_LOADING, true);
    try {
      const filterData = (data) => {
        if (!data) {
          throw 'Undefined catalogGroups response';
        }
        if (!data.groups) {
          throw "Missing 'groups' object";
        }
        // List of compatible booking item types
        // Can add more types to the list as they become compatible
        let allowedTypes = ['Item', 'WebItem'];

        let filteredData = data.groups.filter((x) =>
          allowedTypes.includes(x.type)
        );

        return { groups: filteredData };
      };

      const { data } = await catalogGroupService.getCatalogGroups({
        productIds,
      });
      commit(mutations.SIMILAR_PRODUCTS_LOADED, { products: filterData(data) });
    } catch (error) {
      commit(mutations.SIMILAR_PRODUCTS_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.SIMILAR_PRODUCTS_LOADING, false);
    }
  },
  async loadAvailability({ commit }, { productId, startDate }) {
    const bookingService = RepoFactory.get('booking', {
      resourceId: productId,
    });
    commit(mutations.AVAILABILTY_LOADING, true);

    try {
      const response = await bookingService.getAvailability({
        startDate,
      });

      const groupedData = {};
      groupedData[startDate.substr(0, 7)] = response.data;
      commit(mutations.AVAILABILTY_LOADED, { availability: groupedData });
    } catch (error) {
      commit(mutations.AVAILABILTY_ERROR, { error: error.toString() });
    } finally {
      commit(mutations.AVAILABILTY_LOADING, false);
    }
  },
  async loadPasses({ commit }, { productId, optionId }) {
    const bookingService = RepoFactory.get('booking', {
      resourceId: productId,
    });
    commit(mutations.ORDER_PASSES_READY, { passesReady: false });
    commit(mutations.PASSES_LOADING, true);
    try {
      const response = await bookingService.getPasses({
        optionId,
      });
      commit(mutations.PASSES_LOADED, { passes: response.data });
    } catch (error) {
      commit(mutations.PASSES_ERROR, { error: error.toString() });
    } finally {
      commit(mutations.PASSES_LOADING, false);
    }
  },
  selectOrderAvailabilityDate({ commit }, { availabilityDate }) {
    commit(mutations.ORDER_AVAILABILITY_DATE_SET, {
      availabilityDate: availabilityDate,
    });
  },
  selectOrderOption({ commit }, { option }) {
    commit(mutations.ORDER_OPTION_SET, { option });
    commit(mutations.ORDER_PASSES_CLEAR);
    commit(mutations.ORDER_PASSES_READY, { passesReady: false });
  },

  async loadQuestions({ commit }, { productId, passIds }) {
    const bookingService = RepoFactory.get('booking', {
      resourceId: productId,
    });
    commit(mutations.QUESTIONS_LOADING, true);
    try {
      const response = await bookingService.getQuestions({
        passIds,
      });
      commit(mutations.QUESTIONS_LOADED, {
        questions: response.data,
        passIds,
      });
    } catch (error) {
      const { response } = error;
      let message;
      if (response && response.data) {
        const { errorCode, errorMessage } = response.data;
        message = errorMessage;
        if (errorCode === 1002) {
          message = 'Please Select a Pass';
        }
      } else {
        message = error.toString();
      }

      commit(mutations.QUESTIONS_ERROR, { error: message });
    } finally {
      commit(mutations.QUESTIONS_LOADING, false);
    }
  },

  answerQuestions({ commit }, { answers }) {
    commit(mutations.ORDER_QUESTIONS_SET, { answers: answers });
  },
  setTravelers({ commit }, travelers) {
    commit(mutations.ORDER_QUESTIONS_SET_TRAVELERS, travelers);
  },
  storePassSelection({ commit }, { id, value }) {
    commit(mutations.ORDER_PASSES_SET, { id: id, value: value });
  },
  passesReady({ commit }, { passesReady }) {
    commit(mutations.ORDER_PASSES_READY, { passesReady: passesReady });
  },
  clearQuestions({ commit }) {
    commit(mutations.ORDER_QUESTIONS_CLEARED);
  },
  async createOrder(
    { dispatch, getters, commit },
    { productId, orderInformation, travelerId }
  ) {
    if (!getters.hasLocalOrder) await dispatch('prepareLocalOrder');

    const { amount, selectedPasses, answers } = orderInformation;

    try {
      // map and validate our Booking order
      const order = new BookingOrder({
        amount,
        selectedPasses,
        answers,
        travelerId,
        productId,
      });
      commit(mutations.SET_ORDER_REQUEST, order);
      dispatch(
        'product/createOrderRequest',
        {
          id: getters.recentLocalOrderId,
          order: order,
        },
        {
          root: true,
        }
      );
    } catch (error) {
      dispatch('updateOrderCreationError', {
        error: error.toString(),
      });
      return;
    }
  },

  updateAmount({ commit, rootState }, { value, currency }) {
    commit(mutations.ORDER_UPDATE_AMOUNT, {
      value: value,
      currency: currency || rootState.configs.default_currency,
    });
  },

  checkOutOrder(
    { dispatch, getters },
    { orderId, paymentMethodId, discountToken }
  ) {
    dispatch(
      'product/checkOutOrder',
      {
        id: getters.recentLocalOrderId,
        orderId,
        paymentMethodId,
        discountToken,
      },
      {
        root: true,
      }
    );
  },

  clearOrderCreation({ dispatch, getters }) {
    dispatch(
      'product/clearOrderCreation',
      {
        id: getters.recentLocalOrderId,
      },
      {
        root: true,
      }
    );
  },
  clearOrderInformation({ commit }) {
    commit(mutations.ORDER_INFORMATION_CLEAR);
  },
  clearOrderCheckout({ dispatch, getters }) {
    dispatch(
      'product/clearOrderCheckout',
      {
        id: getters.recentLocalOrderId,
      },
      {
        root: true,
      }
    );
  },
  clearProductAvailability({ commit }) {
    commit(mutations.AVAILABILTY_CLEAR);
  },
  clearProductPasses({ commit }) {
    commit(mutations.PASSES_CLEAR);
  },
  clearProductQuestions({ commit }) {
    commit(mutations.QUESTIONS_CLEAR);
  },
  async prepareLocalOrder({ commit, dispatch }) {
    const { localOrderId } = await dispatch('product/prepareOrder', null, {
      root: true,
    });
    commit(mutations.ORDER_APPEND_LOCAL_ID, localOrderId);
  },
  updateOrderCreationError({ dispatch, getters }, { error }) {
    dispatch(
      'product/updateOrderCreationError',
      {
        id: getters.recentLocalOrderId,
        error,
      },
      {
        root: true,
      }
    );
  },
};
