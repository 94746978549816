<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="px-0" flat>
          <v-alert
            class="mb-1 mx-4 mt-4"
            :value="!instantConfirmation"
            type="info"
            outlined
            text
            color="#306CC5"
          >
            <span class="main-message">
              <b>{{ $t('product.order_in_process') }}</b
              >{{ $t('product.order_card_wont_charge') }}
            </span>
          </v-alert>
          <v-card-title
            data-cy="order-confirmation-status"
            class="pa-0 px-5 px-md-0 mb-4 mt-5 mt-md-0 font-weight-bold"
          >
            <slot name="header-instant" v-if="instantConfirmation"></slot>
            <template v-else>
              <v-icon size="31" color="#306CC5" class="mr-2">mdi-alert</v-icon>
              <span class="mb-1 pending-text">{{
                $t('product.order_pending')
              }}</span>
            </template>
          </v-card-title>
          <v-card-text class="body-1 px-5 px-md-0 pb-10">
            <slot name="main-message-instant" v-if="instantConfirmation">
            </slot>
            <slot name="main-message-pending" v-else />
          </v-card-text>

          <v-card-text
            class="pb-0 body-1 grey1"
            :class="$vuetify.breakpoint.smAndDown ? 'px-5 pt-5' : 'px-6 pt-7'"
          >
            <v-row
              no-gutters
              class="pt-0"
              :class="$vuetify.breakpoint.smAndDown ? 'pb-3' : 'pb-4'"
            >
              <v-col cols="3" sm="2" class="body-2 font-weight-bold mb-1">
                <span class="size16-weight600">{{
                  $t('product.contact')
                }}</span>
              </v-col>

              <v-col cols="9" sm="10">
                <div class="size16-weight400 mb-1">
                  {{ primaryContactFullName }}
                </div>
                <div class="size16-weight400 mb-1">
                  {{ primaryContactEmail }}
                </div>
                <div v-if="primaryContactPhone" class="size16-weight400 mb-1">
                  +{{ primaryContactPhone }}
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters class="pt-0 pb-4">
              <v-col cols="3" sm="2" class="body-2 font-weight-bold mb-1">
                <span class="size16-weight600">{{
                  $t('product.paid_with')
                }}</span>
              </v-col>

              <v-col cols="9" sm="10">
                <template v-if="isPaymentCreditCard">
                  <v-row no-gutters class="mb-1">
                    <v-col cols="auto" class="d-flex justify-start">
                      <v-img alt="visa" src="@/assets/visa.svg" />
                      <span class="size16-weight400 ml-2">
                        {{ creditCardNumber }}
                      </span>
                    </v-col>
                  </v-row>
                </template>

                <template v-else>
                  <div class="mb-2">
                    <span class="size16-weight400">{{
                      paymentMethodType
                    }}</span>
                  </div>
                </template>
              </v-col>
            </v-row>

            <template v-if="orderReferenceId">
              <v-row no-gutters class="pt-0 pb-4">
                <v-col cols="3" sm="2" class="body-2 font-weight-bold mb-1">
                  <span class="size16-weight600">{{
                    $t('product.order_id')
                  }}</span>
                </v-col>

                <v-col cols="9" sm="10">
                  <div class="size16-weight400 mb-1">
                    {{ orderReferenceId }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-card-text>

          <template v-if="displayLoyalty">
            <v-card-text class="pt-0">
              <v-row no-gutters class="body-2 font-weight-bold mb-1">
                <v-col class="col-12 col-sm-6 ml-auto">
                  <v-row
                    no-gutters
                    class="body-2 font-weight-bold mb-1"
                    :class="{ 'mt-2': this.$vuetify.breakpoint.smAndDown }"
                  >
                    <span>{{ $t('product.loyalty_points') }}</span>
                  </v-row>
                  <v-row no-gutters>
                    <span class="earned-points-text">
                      {{ $t('product.earned') + totalEarningPoints }}
                      {{
                        $t('product.points_starred', { points: pointsCurrency })
                      }}
                    </span>
                  </v-row>
                  <v-row no-gutters>
                    <span class="current-points-text">
                      {{
                        $t('product.current_points', {
                          points: currentPoints,
                          currency: pointsCurrency,
                        })
                      }}
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </v-card>

        <template v-if="displayLoyalty">
          <v-row no-gutters class="pt-2 earned-points-disclaimer">
            <v-col cols="12">
              {{ $t('product.earned_points_description') }}
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OrderConfirmation',
  props: {
    instantConfirmation: { type: Boolean, default: true },
    primaryContact: {
      type: Object,
      default: () => {
        return {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        };
      },
    },
    creditCardLastDigits: { type: String, default: '1234' },
    orderReferenceId: { type: String, default: '9876543210' },
    orderDate: {
      type: String,
      default: new Date(Date.now()).toISOString(),
    },
    totalEarningPoints: { type: Number, default: 0 },
    pointsCurrency: { type: String, default: '' },
    productContact: { type: String, default: '' },
    paymentMethodType: String,
  },

  computed: {
    isPaymentCreditCard() {
      return this.paymentMethodType === 'CreditCard';
    },

    primaryContactEmail() {
      return !!this.primaryContact ? this.primaryContact.email : '';
    },

    primaryContactFullName() {
      return !!this.primaryContact
        ? `${this.primaryContact.firstName} ${this.primaryContact.lastName}`
        : '';
    },

    primaryContactPhone() {
      return !!this.primaryContact &&
        this.primaryContact.phone !== null &&
        this.primaryContact.phone !== undefined
        ? this.primaryContact.phone
        : '';
    },

    creditCardNumber() {
      return `****** ${this.creditCardLastDigits}`;
    },

    displayLoyalty() {
      return this.totalEarningPoints > 0;
    },

    currentPoints() {
      return this.$store.getters['profile/userPoints'];
    },
  },
};
</script>

<style scoped lang="scss">
.requires-user-attention {
  position: absolute;
  height: 4px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: var(--v-primary-base);
}
.thick-border.v-card.v-card--flat.v-card--outlined {
  border-width: 2px;
}
.thick-border.thick-border--dashed.v-card.v-card--flat.v-card--outlined {
  border-style: dashed;
}
.thick-border--confirmed-color.v-card.v-card--flat.v-card--outlined {
  border-color: #59a704;
}
.text--break {
  word-wrap: break-word;
  font-size: 28px;
  max-width: 470px;
}
.main-message {
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: black;
}
.pending-text {
  color: #306cc5;
}
.title.payment-summary-title {
  color: #000;
}
.earned-points-text {
  color: #59a704;
  font-style: normal;
  font-weight: normal;
}

.current-points-text {
  font-style: normal;
  font-weight: bold;
}

.earned-points-disclaimer {
  font-size: 14px;
}
</style>
