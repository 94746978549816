import moment from 'moment';

export const getters = {
  hasDetails: (state) =>
    !state.details.isLoading && state.details.value !== null,
  hasSimilarProducts: (state) =>
    !state.similarProducts.isLoading && state.similarProducts.value !== null,
  hasAvailability: (state) =>
    !state.availability.isLoading && state.availability.value !== null,
  hasPasses: (state) => !state.passes.isLoading && state.passes.value !== null,
  hasOrderAvailabilityDate: (state) =>
    state.orderInformation.selectedDate !== null &&
    state.orderInformation.selectedDate !== undefined,
  hasOrderOption: (state) =>
    state.orderInformation.option !== null &&
    state.orderInformation.option !== undefined,
  selectedAvailabilityMonth: (state) => {
    return moment(state.orderInformation.selectedDate)
      .format('YYYY-MM')
      .toString();
  },
  selectedAvailabilityDate: (state, getters) => {
    if (!getters.hasOrderAvailabilityDate) return null;
    const { selectedDate } = state.orderInformation;
    const selectedMonth = getters.selectedAvailabilityMonth;
    return state.availability.value[selectedMonth].find(
      (o) => o.date === selectedDate
    );
  },
  hasSelectedPasses: (state) =>
    state.orderInformation.selectedPasses.length > 0,
  hasCreatedOrder: (state, getters) =>
    getters.orderCreation &&
    getters.orderCreation.response &&
    getters.orderCreation.error === null,
  hasCheckedOutOrder: (state, getters) =>
    getters.orderCheckout &&
    getters.orderCheckout.response &&
    getters.orderCheckout.error === null &&
    getters.orderCheckout.clientConfirmationKey === null,
  recentLocalOrderId: (state) =>
    state.localOrderIds.length > 0
      ? state.localOrderIds[state.localOrderIds.length - 1]
      : null,
  hasLocalOrder: (state) => state.localOrderIds.length > 0,
  localOrder: (state, getters, rootState) =>
    getters.hasLocalOrder
      ? rootState.product.orders[getters.recentLocalOrderId]
      : null,
  orderCreation: (state, getters, rootState) =>
    getters.hasLocalOrder
      ? rootState.product.orders[getters.recentLocalOrderId].creation
      : null,
  orderCheckout: (state, getters, rootState) =>
    getters.hasLocalOrder
      ? rootState.product.orders[getters.recentLocalOrderId].checkout
      : null,
  orderRequest: (state) => state.orderRequest,
  getTravelers: (state) => state.travelers,
  getSelectedPasses: (state) => state.orderInformation.selectedPasses,
  getDetails: (state, getters) =>
    getters.hasDetails ? state.details.value : null,
};
