<template>
  <v-container class="pa-0">
    <component
      :is="getConfirmationType()"
      :instantConfirmation="isInstantConfirmation"
      :primaryContact="customer"
      :orderReferenceId="referenceNumber"
      :creditCardLastDigits="creditCardLast4Digits"
      :totalEarningPoints="totalEarningPoints"
      :pointsCurrency="pointsCurrency"
      :paymentMethodType="paymentMethodType"
    />
    <v-row
      no-gutters
      justify="space-between"
      class="pt-10 ma-0"
      :class="$vuetify.breakpoint.mdAndDown ? 'px-5' : ''"
    >
      <v-btn
        class="primary size16-weight700 payment-button align-self-start"
        data-cy="show-more"
        depressed
        :block="$vuetify.breakpoint.smAndDown"
        :to="{ name: backToPage.link }"
      >
        {{ backToPage.label }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins.vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import LoyaltyMixins from '@/modules/common/mixins/LoyaltyMixins';
import ProductOrderConfirmation from '../OrderConfirmation/ProductOrderConfirmation.vue';
import HealthOrderConfirmation from '@/modules/product/features/OrderConfirmation/HealthOrderConfirmation';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import ProductOrderInformationMixins from '@/modules/product/booking/mixins/ProductOrderInformationMixins';
import ProductDetailsMixins from '@/modules/product/booking/mixins/ProductDetailsMixins';

export default {
  name: 'PaymentStatusPage',
  components: {
    ProductDetailsMixins,
    ProductOrderConfirmation,
    HealthOrderConfirmation,
  },
  mixins: [
    ProductCheckoutMixins,
    GoogleTagManagerMixins,
    LoyaltyMixins,
    SelectedContextFlightMixins,
    ProductOrderInformationMixins,
    ProductDetailsMixins,
  ],
  created() {
    this.pushPurchaseAnalytics();
  },

  async beforeRouteLeave(to, from, next) {
    await this.clearOrderInformation();
    next();
  },
  methods: {
    getConfirmationType() {
      if (this.$route.meta && this.$route.meta.confirmationTypeOverride) {
        return this.$route.meta.confirmationTypeOverride;
      } else {
        return 'ProductOrderConfirmation';
      }
    },
    pushPurchaseAnalytics() {
      if (this.hasCheckedOutOrder && this.checkedOutOrder.amount) {
        const items = {
          item_id: this.detailsModel?.id,
          item_name: this.detailsModel?.title,
          price: this.detailsModel?.priceStartingAt.value,
          quantity: this.checkedOutOrder?.products[0].passes.length,
        };
        const purchase = {
          transaction_id: this.checkedOutOrder?.id,
          value: this.checkedOutOrder?.amount.value,
          currency: this.checkedOutOrder?.amount.baseCurrency,
          items: items,
        };
        this.pushPurchase(purchase);
      }
    },
    clearOrderInformation() {
      if (
        this.$route.meta &&
        this.$route.meta.storeModuleNameFn &&
        this.$route.meta.storeModuleNameFn instanceof Function
      ) {
        return this.$store.dispatch(
          `${this.$route.meta.storeModuleNameFn(
            this.orderProductId
          )}/clearOrderInformation`
        );
      }
    },
  },
  computed: {
    isInstantConfirmation() {
      return (
        this.hasCheckedOutOrder && this.checkedOutOrder.status === 'Confirmed'
      );
    },
    referenceNumber() {
      return this.hasCheckedOutOrder
        ? this.checkedOutOrder.referenceNumber
        : null;
    },
    customer() {
      return this.hasCheckedOutOrder ? this.checkedOutOrder.customer : null;
    },
    creditCardLast4Digits() {
      return this.hasCheckedOutOrder ? this.checkedOutOrder.last4Digits : null;
    },
    paymentMethodType() {
      return this.hasCheckedOutOrder
        ? this.checkedOutOrder.payments[0].paymentMethodType
        : null;
    },
    redirectPathFromConfirmation() {
      return this.$store.getters[`product/redirectPathFromConfirmation`];
    },
    totalEarningPoints() {
      return this.hasLoyaltyData
        ? this.checkedOutOrder.pointsEarned.value
        : null;
    },
    pointsCurrency() {
      return this.hasLoyaltyData
        ? this.checkedOutOrder.pointsEarned.baseCurrency
        : null;
    },
    hasLoyaltyData() {
      return (
        this.enableLoyalty &&
        this.hasCheckedOutOrder &&
        this.checkedOutOrder.pointsEarned !== undefined &&
        this.checkedOutOrder.pointsEarned !== null
      );
    },
    backToPage() {
      if (this.$route.name === 'transportation-payment-status') {
        return {
          link: 'transportationHome',
          label: this.$t('product.back_to_transportations'),
        };
      } else {
        return {
          link: 'discoveryWithCity',
          label: this.$t('booking.back_to_experiences'),
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.post-purchase-nav-button {
  color: var(--v-grey9-base);
}
::v-deep.payment {
  &-button {
    padding: 12px 20px !important;
    border-radius: 8px;
    height: 54px !important;
    letter-spacing: 1px;
  }
}
::v-deep.payment {
  &-button {
    padding: 12px 20px !important;
    border-radius: 8px;
    height: 54px !important;
    letter-spacing: 0px;
  }
}
</style>
