<template>
  <OrderConfirmation
    :instantConfirmation="instantConfirmation"
    :primaryContact="primaryContact"
    :orderReferenceId="orderReferenceId"
    :creditCardLastDigits="creditCardLastDigits"
    :totalEarningPoints="totalEarningPoints"
    :pointsCurrency="pointsCurrency"
    :paymentMethodType="paymentMethodType"
  >
    <template v-slot:header-instant>
      <div class="d-flex align-center">
        <v-icon size="31" color="var(--v-success-base)" class="mr-2">
          mdi-checkbox-marked-circle
        </v-icon>
        <span class="mb-1 confirmed-text">
          {{ $t('product.booking_confirmed') }}
        </span>
      </div>
    </template>

    <template v-slot:main-message-instant>
      <div class="main-message instant">
        {{ $t('product.thanks_for_booking') }}
        <span class="size18-weight600">{{ primaryContactEmail }}</span
        >.
      </div>
    </template>

    <template v-slot:main-message-pending>
      <p class="main-message">
        {{ $t('product.operator_checking_availability') }}<br /><br />
        {{ $t('product.when_order_confirmed') }}<b>{{ primaryContactEmail }}</b
        >{{ $t('product.within') }}<b>{{ $t('product.confirmation_time') }}</b
        >.
      </p>
    </template>
  </OrderConfirmation>
</template>

<script>
import OrderConfirmation from './OrderConfirmation';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';

export default {
  components: { OrderConfirmation },
  mixins: [TravelerMixins],
  props: {
    instantConfirmation: { type: Boolean, default: true },
    primaryContact: {
      type: Object,
      default: () => {
        return {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        };
      },
    },
    creditCardLastDigits: { type: String, default: '1234' },
    orderReferenceId: { type: String, default: '9876543210' },
    totalEarningPoints: { type: Number, default: 0 },
    pointsCurrency: { type: String, default: '' },
    productContact: { type: String, default: '' },
    paymentMethodType: String,
  },

  computed: {
    primaryContactEmail() {
      return !!this.primaryContact ? this.primaryContact.email : 'xxxx';
    },
  },
};
</script>

<style scoped lang="scss">
.main-message {
  line-height: 27px;
  color: black;

  @media (min-width: map-get($grid-breakpoints, md)) {
    line-height: 24px;
  }

  &.instant {
    @include font-size(18, 133.3334, 400);

    @media (min-width: map-get($grid-breakpoints, sm)) {
      @include font-size(18, 150, 400);
    }
  }
}
.confirmed {
  &-title {
    margin-top: 20px;
  }

  &-text {
    display: contents;
    color: var(--v-success-base);
    @include font-size(20, 150, 300);

    @media (min-width: map-get($grid-breakpoints, sm)) {
      @include font-size(30, 100, 300);
    }
  }
}
</style>
