<template>
  <OrderConfirmation
    :instantConfirmation="instantConfirmation"
    :primaryContact="primaryContact"
    :orderReferenceId="orderReferenceId"
    :creditCardLastDigits="creditCardLastDigits"
    :totalEarningPoints="totalEarningPoints"
    :pointsCurrency="pointsCurrency"
    :paymentMethodType="paymentMethodType"
  >
    <template v-slot:header-instant>
      <div class="mt-2">
        <v-icon size="31" color="#59a704" class="mb-md-2">
          mdi-checkbox-marked-circle
        </v-icon>
        <span class="mb-1 confirmed-text">
          {{ $t('product.booking_confirmed') }}
        </span>
      </div>
    </template>

    <template v-slot:main-message-instant>
      <p class="main-message">
        {{ $t('product.health_booking_confirmation_message') }}
        <b>{{ primaryContactEmail }}.</b>
      </p>
      <p class="main-message mt-3">
        {{ $t('product.health_order_number_is') }}
        <b>{{ orderReferenceId }}</b>
        . {{ $t('product.health_phone_number_warn') }}
      </p>
      <div class="pt-3 footer-message">
        <h3>{{ $t('product.health_whats_next') }}</h3>
        <p class="mb-1 mt-2">
          {{ $t('product.health_welcome_account_activation') }}
        </p>
        <p class="">
          {{ $t('product.health_long_activation_warn') }}
        </p>
      </div>
    </template>

    <template v-slot:main-message-pending>
      <p class="main-message">
        {{ $t('product.health_checking_availability') }}
        <br /><br />
        {{ $t('product.health_when_confirmed') }}
        <b>{{ primaryContactEmail }}</b>
        {{ $t('product.within') }}
        <b>{{ $t('product.confirmation_time') }}</b
        >.
      </p>
    </template>
  </OrderConfirmation>
</template>

<script>
import OrderConfirmation from '@/modules/product/features/OrderConfirmation/OrderConfirmation';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';

export default {
  name: 'HealthOrderConfirmation',
  components: { OrderConfirmation },
  mixins: [TravelerMixins],
  props: {
    instantConfirmation: { type: Boolean, default: true },
    primaryContact: {
      type: Object,
      default: () => {
        return {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        };
      },
    },
    creditCardLastDigits: { type: String, default: '1234' },
    orderReferenceId: { type: String, default: '9876543210' },
    totalEarningPoints: { type: Number, default: 0 },
    pointsCurrency: { type: String, default: '' },
    productContact: { type: String, default: '' },
    paymentMethodType: String,
  },

  computed: {
    primaryContactEmail() {
      return !!this.primaryContact ? this.primaryContact.email : 'xxxx';
    },
  },
};
</script>

<style scoped lang="scss">
.footer-message {
  color: black;
}
.main-message {
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: black;
}
.confirmed-text {
  color: #59a704;
  @include font-size(20, 150, 300);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(30, 150, 300);
  }
}
</style>
